import { typography } from "aviary-tokens";
import { success, text } from "aviary-tokens/dist/tokens/ts/themes/light";

import { layers } from "@styles";

const baseThemeOptions = {
  palette: {
    primary: {
      main: success.textBase,
    },
    text: {
      primary: text.body,
    },
  },
  typography: {
    fontFamily: typography.fontFamilySansSerif,
  },
  zIndex: {
    modal: layers.indexModal,
  },
};

export { baseThemeOptions };
