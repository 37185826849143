import { useReactiveVar, type ReactiveVar } from "@apollo/client";
import type { ReactElement } from "react";

import { makeFSVar } from "@shared/reactiveVar/makeFSVar";

import type { ToastType } from "./toastTypes";

interface ToastOptions {
  autoDismiss?: boolean;
  autoDismissTimeout?: number;
  toastId?: string;
  isCloseable?: boolean;
  onClose?: () => void;
}

type Item = {
  key: string | number;
  type: ToastType;
  content: ReactElement | string;
  options?: ToastOptions;
};

const MAX_NUMBER_TOASTS = 3;

let id = 0;
let toastVar: ReactiveVar<Item[]> = makeFSVar<Item[]>([], "toaster");

/**
 * Only to be used to reset the var to undefined so it can get initialized later
 * Useful for writing specs and reseting in between them
 */
const resetToastVar = () => {
  const initialItems: Item[] = [];
  toastVar = makeFSVar(initialItems, "toaster");
};

const makeToast = (type: Item["type"], content: Item["content"], options?: Item["options"]) => {
  const items = toastVar();

  if (items.length > MAX_NUMBER_TOASTS) return;
  const uniqID = options?.toastId ? options.toastId : id++;

  return toastVar([...items, { key: uniqID, type, content, options }]);
};

const doCallBack = (key: Item["key"]) => {
  const items = toastVar();

  items.filter(item => item.key === key).forEach(item => item.options?.onClose?.());
};

const removeToast = (key: Item["key"]) => {
  const items = toastVar();
  doCallBack(key);

  toastVar(items.filter(i => i.key !== key));
};

const useToastVar = () => useReactiveVar(toastVar);

export { useToastVar, makeToast, removeToast, resetToastVar, type ToastOptions, type Item };
