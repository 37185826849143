// @ts-strict-ignore
import { useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { colorProfileMapper } from "aviary-tokens";
import type { HTMLProps, Ref } from "react";
import { useTranslation } from "react-i18next";

import { useIsDS4Enabled } from "@aviary/hooks/useIsDS4Enabled";
import { l } from "@aviary/locales/i18n";
import { FontAwesomeIcon } from "@shared/react-fontawesome";
import { Link } from "@shared/react-router-dom";

import { Skeleton } from "../Skeleton";

import type { ButtonProps, ButtonOrLinkType } from "./Button.types";
import { DeprecatedButton } from "./DeprecatedButton";

import * as styles from "./Button.styles";

const DS4IntentionsToDS3Props = {
  primary: { isColor: "primary" },
  system: { isColor: "system" },
  danger: { isColor: "danger", isOutlined: true },
  textSuccess: { isColor: "success", isText: true },
  textSystem: { isColor: "system", isText: true },
  textDanger: { isColor: "danger", isText: true },
  lightFilled: { isLight: true },
  lightOutlined: { isLight: true, isOutlined: true },
  lightText: { isLight: true, isText: true },
};

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/interaction/Button
 */
const Button = ({
  isColor = "primary",
  size = "medium",
  isSkeleton = false,
  ...props
}: ButtonOrLinkType) => {
  const { t } = useTranslation("aviary");
  const currentTheme = useTheme();
  const isDS4Enabled = useIsDS4Enabled();

  if (!isDS4Enabled) {
    const { intention, ...rest } = props;
    const DS3Props = DS4IntentionsToDS3Props[intention] as ButtonProps;

    return <DeprecatedButton {...{ size, isSkeleton, isColor, ...rest }} {...DS3Props} />;
  }

  const {
    children,
    disabled,
    href,
    innerRef,
    intention: receivedIntention,
    isCircular,
    isFullWidth,
    isIcon,
    isLight,
    isLoading,
    isOutlined,
    isText,
    latch,
    noStyle,
    replace,
    to,
    type,
    isLoaderDisabled,
    ...rest
  } = props;

  // Convert DS3 Button props to DS4 Intentions IF no intention is passed
  let intention = receivedIntention;

  if (!receivedIntention) {
    switch (true) {
      case isLight && isOutlined:
        intention = "lightOutlined";
        break;
      case isLight && isText:
        intention = "lightText";
        break;
      case isLight:
        intention = "lightFilled";
        break;

      case isText && isColor === "primary":
        intention = "textSuccess";
        break;
      case isText && isColor === "danger":
        intention = "textDanger";
        break;
      case isText:
        intention = "textSystem";
        break;
      case isOutlined && isColor !== "danger":
        intention = "secondary";
        break;
      case isColor === "system":
        intention = "secondary";
        break;
      case isColor === "primary":
        intention = "primary";
        break;
      case isColor === "danger":
        intention = "danger";
        break;

      default:
        intention = "secondary";
    }
  }

  if (intention === "system") {
    intention = "secondary";
  }

  const themeColors = colorProfileMapper(currentTheme);

  const buttonBuilder = () => {
    if (noStyle) return styles.button.noStyle;

    return [
      isLoading && styles.loadingStyles,
      styles.button.base,
      styles.latch(themeColors[isColor], currentTheme)[latch],
      disabled && styles.latch(themeColors[isColor], currentTheme).disabled,
      isFullWidth && styles.button.fullwidth,
      isCircular && styles.circular,
      styles.button[size],
      isIcon && iconBuilder(),
      isLight && lightButtonBuilder(),
      styles.intentionStyles[intention],
    ];
  };

  const lightButtonBuilder = () => {
    return (
      !noStyle && [
        styles.lightStyles.base(currentTheme),
        // TODO: Figure out DS4 Outlined and Text Light button styles
        isOutlined && styles.lightStyles.outlined(currentTheme),
        isText && styles.lightStyles.isText(currentTheme),
        isIcon && styles.lightStyles.isIcon(currentTheme),
        isIcon && isText && styles.lightStyles.isText(currentTheme),
        isIcon && isOutlined && styles.lightStyles.outlined(currentTheme),
      ]
    );
  };

  const iconBuilder = () => {
    return (
      !noStyle && [
        isIcon && styles.baseIconStyles,
        isIcon && styles.iconStyles[size],
        styles.normalSVG(currentTheme),
      ]
    );
  };

  const loaderBuilder = () => {
    return (
      !noStyle && [
        styles.loaderIcon,
        styles.normalSVG(currentTheme),
        isLight && styles.lightStyles.isIcon(currentTheme),
      ]
    );
  };

  const renderLoader = () => {
    if (!noStyle && !isLoaderDisabled && isLoading) {
      return (
        <div css={styles.loader}>
          <FontAwesomeIcon
            css={loaderBuilder()}
            icon={faSpinnerThird}
            spin
            title={t(l.aviary.button.Loading)}
          />
        </div>
      );
    }
  };

  const getValidButtonType = () => {
    if (type === "submit" || type === "reset") {
      return type;
    } else {
      return "button";
    }
  };

  const button = () => (
    <button
      css={buttonBuilder()}
      disabled={disabled}
      {...(rest as Omit<HTMLProps<HTMLButtonElement>, "size">)}
      ref={innerRef as Ref<HTMLButtonElement>}
      type={getValidButtonType()}
    >
      {renderLoader()}
      {children}
    </button>
  );

  const anchor = () => (
    <a
      css={buttonBuilder()}
      role="button"
      href={href}
      {...(rest as Omit<HTMLProps<HTMLAnchorElement>, "size">)}
      ref={innerRef as Ref<HTMLAnchorElement>}
      aria-disabled={isLoading}
    >
      {renderLoader()}
      {children}
    </a>
  );

  const routerLink = () => (
    <Link
      css={buttonBuilder()}
      to={to}
      replace={replace}
      data-testid="Link"
      {...rest}
      ref={innerRef as Ref<HTMLAnchorElement>}
    >
      {renderLoader()}
      {children}
    </Link>
  );

  const getElement = () => {
    if (isSkeleton) {
      return <Skeleton type="button" buttonSize={size} isFullWidth={isFullWidth} />;
    } else if (!disabled) {
      if (to) {
        return routerLink();
      } else if (href) {
        return anchor();
      }
    }

    return button();
  };

  return getElement();
};

export { Button };
