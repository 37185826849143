// @ts-strict-ignore
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { ThemeProvider as MaterialThemeProvider } from "@mui/material/styles";
import { light, lightDS3, dark, emerson } from "aviary-tokens/themes";
import type { AviaryTheme } from "aviary-tokens/themes";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import { SnackWrap } from "@aviary/components/Snackbar/SnackWrap/SnackWrap";
import { Toaster } from "@aviary/components/Toast/Toaster/Toaster";
import { usePreferredUserColorTheme } from "@aviary/hooks/usePreferredUserColorTheme";
import { BreakpointsProvider } from "@shared/context/BreakpointsContext";
import { isLocalStorageEnabled } from "@shared/helpers/isLocalStorageEnabled";
import { useIsFullscriptJsVar } from "@shared/reactiveVar/useIsFullscriptJs/useIsFullscriptJs.store";

import { AviaryGlobalStyles } from "../AviaryGlobalStyles";
import { AviaryThemeContext, type ThemeTypes } from "../AviaryThemeContext";
import * as styles from "../materialTheme";

import { ThemeKeyboardShorcut } from "./ThemeKeyboardShortcut";

interface Props {
  /*
   * Toggles a white background instead of muted blue
   */
  isWhiteBackground?: boolean;
  /**
   * Basically the rest of the app
   *
   * @default undefined
   */
  children?: ReactNode;
  /*
   * Disables normalization (ONLY FOR USE WITHIN ADMIN)
   * SERIOUSLY, THIS PAINS ME TO ADD
   * Only pigeons can use this
   */
  noNormalization?: boolean;
  /**
   * Allows to control the theme from a parent component, e.g, Docusaurus, or checkout
   */
  controlledTheme?: ThemeTypes;
  /**
   * Boolean to enable Emerson theme instead of Fullscript
   * Doesn't work with dark mode
   */
  isEmerson?: boolean;
  /**
   * Boolean for Patient entry points
   */
  isPatient?: boolean;
  /**
   * Boolean for Clinic entry points
   */
  isClinic?: boolean;
  /**
   * Boolean whether React has been hydrated . This is mainly used for SSR
   */
  hydrated?: boolean;
}

const getStoredTheme = () => {
  let storedTheme = null;
  if (typeof window !== "undefined" && isLocalStorageEnabled()) {
    storedTheme = localStorage.getItem("aviaryTheme");
  }

  return storedTheme;
};

const getTheme = (controlledTheme: ThemeTypes, isDS4Visuals, isPrefersDark, isEmerson) => {
  if (isEmerson) {
    return emerson;
  }
  if (!isDS4Visuals) return lightDS3;

  if (controlledTheme) {
    return controlledTheme === "dark" ? dark : light;
  }

  if (getStoredTheme() === "dark" || (isPrefersDark && !getStoredTheme())) {
    return dark;
  }

  return light;
};

const useDetermineExperimentTheme = (shouldBeExperimented, isEmerson, isPatient) => {
  const isFullscriptJs = useIsFullscriptJsVar();

  const isDS4Visuals = isEmerson || isPatient || (shouldBeExperimented && !isFullscriptJs);
  const isDS3Visuals = !isDS4Visuals;

  return { isDS4Visuals, isDS3Visuals };
};

const AviaryProvider = ({
  isWhiteBackground,
  noNormalization,
  controlledTheme,
  isEmerson,
  isPatient,
  isClinic,
  hydrated,
  children,
}: Props) => {
  const shouldBeExperimented = !!isClinic;

  const { isPrefersDark } = usePreferredUserColorTheme();
  const { isDS4Visuals, isDS3Visuals } = useDetermineExperimentTheme(
    shouldBeExperimented,
    isEmerson,
    isPatient
  );

  const [currentTheme, setCurrentTheme] = useState<AviaryTheme>(
    getTheme(controlledTheme, isDS4Visuals, isPrefersDark, isEmerson)
  );

  const temporarilyDisableTransitions = () => {
    const css = document.createElement("style");
    css.appendChild(
      document.createTextNode(
        `*{-webkit-transition:none!important;-moz-transition:none!important;-o-transition:none!important;-ms-transition:none!important;transition:none!important}`
      )
    );
    document.head.appendChild(css);

    return setTimeout(() => {
      document.head.removeChild(css);
    }, 1000);
  };

  const updateTheme = (dataTheme: "light" | "dark", theme: AviaryTheme) => {
    if (isEmerson) {
      setCurrentTheme(emerson);

      return;
    }

    // This is temporarly commented out until we can get the ADA iFrame to work with the new theme
    // For some reason, if you set the data-theme on the html element, it breaks the iFrame default theme
    // to be a white theme, instead of being transparent
    // document?.querySelector("html")?.setAttribute("data-theme", dataTheme);

    document?.querySelector("html")?.setAttribute("data-fs-theme", dataTheme);
    setCurrentTheme(theme);
  };

  useEffect(() => {
    setCurrentTheme(getTheme(controlledTheme, isDS4Visuals, isPrefersDark, isEmerson));
  }, [isDS4Visuals, controlledTheme, isEmerson]);

  const setTheme = (theme: ThemeTypes) => {
    if (!isDS4Visuals) {
      return null;
    }
    if (theme === "light") {
      temporarilyDisableTransitions();
      updateTheme("light", light);
      localStorage.setItem("aviaryTheme", "light");
    }
    if (theme === "dark") {
      temporarilyDisableTransitions();
      updateTheme("dark", dark);

      localStorage.setItem("aviaryTheme", "dark");
    }
  };

  /* NOTE: Material Theme provider must be rendered FIRST, as it
   * also utilizes emotion to theme itself. If this is not done,
   * theming breaks during runtime in some MUI elements
   *
   * Documentation can be found under the interoperability on MUI:
   * https://mui.com/material-ui/guides/interoperability/#theme
   */
  return (
    <BreakpointsProvider hydrated={hydrated}>
      <MaterialThemeProvider theme={styles.materialAviaryTheme(currentTheme)}>
        <EmotionThemeProvider theme={currentTheme}>
          <AviaryThemeContext.Provider value={{ setTheme, isDS4Visuals, isDS3Visuals }}>
            <AviaryGlobalStyles
              isWhiteBackground={isWhiteBackground}
              noNormalization={noNormalization}
            />
            <ThemeKeyboardShorcut {...{ isEmerson, controlledTheme, setTheme, currentTheme }} />
            <SnackWrap>
              <Toaster>
                <>{children}</>
              </Toaster>
            </SnackWrap>
          </AviaryThemeContext.Provider>
        </EmotionThemeProvider>
      </MaterialThemeProvider>
    </BreakpointsProvider>
  );
};

export { AviaryProvider };
