// @ts-strict-ignore
const isLocalStorageEnabled = () => {
  try {
    const key = `__storage__test`;
    window.localStorage.setItem(key, null);
    window.localStorage.removeItem(key);

    return true;
  } catch (e) {
    return false;
  }
};

export { isLocalStorageEnabled };
