// @ts-strict-ignore
import type { ReactNode } from "react";
import { createContext, useEffect, useState } from "react";

import { useServer } from "@shared/hooks/useServer/useServer";
import { breakpoints } from "@styles/emotion-styles/dimensions";

type Breakpoint = keyof typeof breakpoints;
interface BreakpointDetails {
  min: number;
  max: number;
  active: boolean;
  greaterThan: boolean;
  lessThan: boolean;
}

interface BreakpointsActiveMap extends Record<Breakpoint, BreakpointDetails> {
  width: number;
}

interface Props {
  hydrated?: boolean;
  children: ReactNode;
}

const BreakpointsContext = createContext<any>({
  width: 0,
  ...breakpoints,
});

export const BreakpointsProvider = ({ children, hydrated = true }: Props) => {
  const { ssr, deviceType } = useServer();

  /**
   * Gets the inner width of the screen.
   *
   * Of note in SSR mode on the initial render in the server,
   * we do not have access to the window object. So instead we reference the deviceType
   * from the server context which is determined by the user agent. Then on the client
   * side we need to wait for hydration to complete before we can access the window object so
   * as not to cause a mismatch between the server and client rendered content. Before hydration
   * is complete we can reference the deviceType from the window object which is set in a script tag
   * by the server (see `app/javascript/remix/components/Document/Document.tsx`). In this way we ensure
   * in all cases everything is consistent between the server and client.
   */
  const getInnerWidth = () => {
    if (!ssr && hydrated) return window.innerWidth;

    if (
      deviceType === "mobile" ||
      (typeof window !== "undefined" && window.deviceType === "mobile")
    ) {
      return breakpoints.phone.max;
    } else if (
      deviceType === "tablet" ||
      (typeof window !== "undefined" && window.deviceType === "tablet")
    ) {
      return breakpoints.tablet.max;
    }

    return breakpoints.fullHD.max;
  };

  const [width, setWidth] = useState(getInnerWidth());

  const handleResize = () => {
    setWidth(getInnerWidth());
  };

  useEffect(() => {
    if (!window) return;
    window.addEventListener("resize", handleResize, { capture: true });

    if (hydrated) {
      // Once hydration is finished, we need to call handleResize to calculate the inner width of the screen otherwise
      // the inner width would be an estimation based on the device type
      handleResize();
    }

    return () => window.removeEventListener("resize", handleResize, { capture: true });
  }, [hydrated]);

  const breakpointLessThan = (breakpoint: Breakpoint) => width < breakpoints[breakpoint].min;
  const breakpointGreaterThan = (breakpoint: Breakpoint) => width > breakpoints[breakpoint].max;
  const breakpointActive = (breakpoint: Breakpoint) => {
    return !breakpointLessThan(breakpoint) && !breakpointGreaterThan(breakpoint);
  };

  const breakpointDetails = (breakpoint: Breakpoint): BreakpointDetails => ({
    min: breakpoints[breakpoint].min,
    max: breakpoints[breakpoint].max,

    active: breakpointActive(breakpoint),
    greaterThan: breakpointGreaterThan(breakpoint),
    lessThan: breakpointLessThan(breakpoint),
  });

  const breakpointsActiveMap = Object.keys(breakpoints).reduce((map, breakpoint: Breakpoint) => {
    map[breakpoint] = breakpointDetails(breakpoint);

    return map;
  }, {});

  return (
    <BreakpointsContext value={{ width, ...(breakpointsActiveMap as BreakpointsActiveMap) }}>
      {children}
    </BreakpointsContext>
  );
};

export { BreakpointsContext, type BreakpointsActiveMap };
