// @ts-strict-ignore
/* eslint-disable no-restricted-imports, @fullscript/cross-reference */
import * as remix from "@remix-run/react";
import * as router from "react-router-dom";

import { isRemix } from "@shared/utils/fullscriptEnv/fullscriptEnv";

type LinkProps = router.LinkProps & remix.LinkProps;

/**
 * A Link component wrapping Link from Remix and react-router-dom
 * It can be used in interchangeably in a Remix or react-router-dom based SPA and work as expected
 * Means that devs don't need to worry about where an app is running and what API to use
 */
const Link = props => {
  if (isRemix()) {
    return <remix.Link {...props} />;
  }

  return <router.Link {...props} />;
};

export { Link, type LinkProps };
