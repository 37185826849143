// @ts-strict-ignore
import type { ReactNode } from "react";
import { useState } from "react";
import { useTransition, a } from "react-spring";

import { ToastController } from "../ToastController";
import { useToastVar, removeToast } from "../toast.store";

import * as styles from "./Toaster.styles";

interface Props {
  children?: ReactNode;
}

const Toaster = ({ children }: Props) => {
  const [refMap] = useState(() => new WeakMap());
  const [cancelMap] = useState(() => new WeakMap());
  const items = useToastVar();

  const transitions = useTransition(items, {
    from: { opacity: 0, height: 0 },
    enter: item => async next =>
      await next({ opacity: 1, height: refMap.get(item).offsetHeight + 8 }),
    leave: item => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({ opacity: 0 });
      await next({ height: 0 });
    },
    keys: item => item.key,
  });

  const renderToasts = () => (
    <div css={styles.base}>
      {transitions((style, item) => (
        <a.div style={style}>
          <div
            ref={ref => {
              if (ref) {
                refMap.set(item, ref);
              }
            }}
          >
            <ToastController
              type={item.type ? item.type : null}
              autoDismiss={item.options?.autoDismiss}
              autoDismissTimeout={item.options?.autoDismissTimeout}
              onDismiss={() => removeToast(item.key)}
              isCloseable={item.options?.isCloseable}
            >
              {item.content}
            </ToastController>
          </div>
        </a.div>
      ))}
    </div>
  );

  return (
    <>
      {renderToasts()}
      {children}
    </>
  );
};

export { Toaster };
