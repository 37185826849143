// @ts-strict-ignore
import { createContext } from "react";

const SNACK_STATES = {
  normal: "normal",
  loading: "loading",
  success: "success",
};

const SNACK_POSITIONS = {
  topLeft: "topLeft",
  topRight: "topRight",
  bottomLeft: "bottomLeft",
  bottomRight: "bottomRight",
};

export type SnackType = keyof typeof SNACK_STATES;
export type SnackPosition = keyof typeof SNACK_POSITIONS;

interface SnackbarData {
  isSnackbarOpen: boolean;
  message: string;
  status: string;
  position: SnackPosition;
  closeSnack: (message: string) => void;
  setSnackMessage: (status: SnackType, message: string, position?: SnackPosition) => void;
}

const SnackbarContext = createContext<SnackbarData>(null);

export type { SnackbarData };
export { SnackbarContext };
