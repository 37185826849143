// @ts-strict-ignore
import ReactRailsUJS from "react_ujs";

import { isModernBrowser } from "./isModernBrowser";
import { loadPolyfillSrcs, POLYFILL_SRCS } from "./loadPolyfillSrcs";

ReactRailsUJS.mountComponentsOriginal = ReactRailsUJS.mountComponents;
/* eslint-disable prefer-arrow/prefer-arrow-functions */
ReactRailsUJS.mountComponents = function (...args) {
  const srcs = window[POLYFILL_SRCS];

  if (!isModernBrowser()) {
    loadPolyfillSrcs(srcs, () => {
      ReactRailsUJS.mountComponentsOriginal(...args);
    });
  } else {
    ReactRailsUJS.mountComponentsOriginal(...args);
  }
};

export default ReactRailsUJS;
