import { useEffect, useLayoutEffect } from "react";

import { isSSR } from "@shared/utils/fullscriptEnv/fullscriptEnv";

/**
 * Whenever using `useLayoutEffect` in an Server Sider Rendered environment, a bunch of warnings occur.
 * The solution to this is to conditionally check the environment and return
 * either `useEffect` or `useLayoutEffect` based on client vs server environment
 */
export const useIsomorphicLayoutEffect =
  isSSR() || process.env.NODE_ENV === "test" ? useEffect : useLayoutEffect;
