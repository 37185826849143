import { useRef } from "react";

import { useIsomorphicLayoutEffect } from "@shared/hooks/useIsomorphicLayoutEffect/useIsomorphicLayoutEffect";

type Func = (value: any) => any;

type Result<T extends Func> = (...value: Parameters<T>) => ReturnType<T>;

/**
 * @description React hook that every time returns the same function that calls the latest callback.
 * Consider the below example, this way the listener won't change with every re-render and so no re-assigning of the listener happanening
 *
 * @example
 *
 * // somwhere in code
 * useKeyCombo({
 *  onPress: () => currentTheme === "dark" ? setTheme("light") : setTheme("dark");
 * })
 *
 * // the correct useKeyCombo.ts implementation would be the following
 *
 * const { onPress } = props;
 * const onPressLatest = useLatestCallback(onPress);
 *
 * useEffect(() => {
 *   if (isCombinationActive) {
 *     onPressLatest();
 *   }
 * }, [isCombinationActive, onPressLatest]);
 *
 * @param cb callback function
 *
 * @returns
 */
const useLatestCallback = <T extends Func>(cb: T): Result<T> => {
  const initialRef = useRef(cb);

  const latestCallbackRef = useRef<Result<T>>((...args) => {
    return initialRef.current.apply(this, args);
  });

  useIsomorphicLayoutEffect(() => {
    initialRef.current = cb;
  }, [cb]);

  return latestCallbackRef.current;
};

export { useLatestCallback };
