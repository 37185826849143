import type { ReactiveVar } from "@apollo/client";
import { useReactiveVar } from "@apollo/client";

import { makeFSVar } from "@shared/reactiveVar/makeFSVar";

const isFullscriptJsVar: ReactiveVar<boolean> = makeFSVar(false, "useIsFullscriptJsVar");

const setIsFullscriptJsVar = (newVal: boolean) => {
  isFullscriptJsVar(newVal);
};

const useIsFullscriptJsVar = (): boolean => {
  return useReactiveVar(isFullscriptJsVar);
};

export { useIsFullscriptJsVar, setIsFullscriptJsVar, isFullscriptJsVar };
