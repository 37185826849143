import * as palette from "./palette";

/*
 * NEW DS3 Color spectrums
 */

// Greens

// Greys
export const grey = {
  base: palette.gray80,
  darkFamily: {
    // Active states
    darker: palette.gray100,
    // Focus states
    dark: palette.gray90,
    // Default states
    normal: palette.gray80,
    // Hover states
    light: palette.gray70,
  },
  lightFamily: {
    darker: palette.gray30,
    dark: palette.gray20,
    normal: palette.gray10,
    light: palette.gray05,
  },
};

// Blues
export const blue = {
  base: palette.blue70,
  darkFamily: {
    darker: palette.blue100,
    dark: palette.blue90,
    normal: palette.blue80,
    light: palette.blue70,
  },
  lightFamily: {
    darker: palette.blue30,
    dark: palette.blue20,
    normal: palette.blue10,
    light: palette.blue05,
  },
};

// Names/Aliases
export const dark = grey.darkFamily.dark;
export const white = palette.white;
export const black = palette.black;

// Background
export const backgroundWhite = palette.white;

// Social media icon colors
export const facebookBlue = palette.facebookBlue;
export const twitterBlue = palette.twitterBlue;
export const linkedInBlue = palette.linkedInBlue;
