// @ts-strict-ignore
const isModernBrowser = (): boolean => {
  return (
    window.Promise &&
    window.fetch &&
    window.Symbol &&
    window.ResizeObserver &&
    window.IntersectionObserver &&
    Array.prototype.at &&
    !!Intl?.ListFormat
  );
};

export { isModernBrowser };
