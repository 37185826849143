/**
 * Retrieves an environment variable from either browser or server context.
 *
 * In browser context, looks for the requested key in window.ENV
 * In server context, looks for the requested key in process.env
 *
 * @param {EnvKey} key - The environment variable key to look up
 * @returns {string | undefined} The value of the environment variable as a string for constistency if found, otherwise undefined
 */

// Define a type that extracts the keys of the ENV object from Window.ENV
type EnvKey = keyof NonNullable<Window["ENV"]>;

export const getEnv = (key: EnvKey): string | undefined => {
  if (typeof window !== "undefined") {
    const value = window.ENV?.[key];

    return value !== undefined ? `${value}` : undefined;
  }

  if (typeof process !== "undefined") {
    return process.env?.[key];
  }

  return undefined;
};
