import type { Theme } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";

import { baseThemeOptions } from "@aviary/theme";
import { baseStyles } from "@styles";

export const materialAviaryTheme = (theme: Theme) =>
  createTheme({
    ...baseThemeOptions,
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            ...baseStyles.modalStylesObject(theme),
            backgroundColor: theme.surface.overlayTheme,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: theme.text.body,
            fontSize: "1rem",

            "&.Mui-error": {
              color: theme.input.textInformationError,
            },
          },
          filled: {
            color: theme.input.textFloatingLabel,
            fontSize: "1rem",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            transform: "translate(1rem, 1rem) scale(1)",

            "&.Mui-focused": {
              color: `${theme.input.textFloatingLabel}`,
            },
          },
          filled: {
            "&.MuiFormLabel-filled": {
              transform: "translate(1rem, 7px) scale(0.85)",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            width: "100%",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            border: `1px solid ${theme.input.borderBase}`,
            borderBottom: "none",

            "&.Mui-error": {
              borderColor: theme.input.borderError,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: `${theme.input.backgroundBase} !important`,
            ":after": {
              borderBottom: `1px solid ${theme.input.borderActive}`,
            },
            ":before": {
              borderBottom: `1px solid ${theme.input.borderEmphasized}`,
            },

            "&.Mui-error": {
              backgroundColor: `${theme.input.backgroundInformation} !important`,
            },
          },
          input: {
            paddingLeft: "1rem",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            backgroundColor: theme.surface.level1,
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            backgroundColor: theme.surface.level1,
            color: theme.text.emphasis,
            "& .MuiIconButton-root": {
              color: theme.text.emphasis,
              "&.MuiPickersArrowSwitcher-button": {
                color: theme.text.subdued,
              },
            },
            "& .MuiTypography-root": {
              color: theme.text.subdued,
              "&.PrivatePickersMonth-root:not(.Mui-selected)": {
                color: theme.text.emphasis,
              },
            },

            "& .MuiButtonBase-root": {
              backgroundColor: "transparent",

              "&.MuiPickersDay-root.Mui-selected": {
                backgroundColor: theme.primary.backgroundBase,
                color: theme.primary.textBase,
              },
              "&.MuiPickersDay-root:not(.Mui-selected)": {
                color: theme.text.emphasis,
                "&:hover": {
                  backgroundColor: theme.system.backgroundMuted,
                },
              },
              "&.Mui-disabled": {
                color: theme.disabled.text,
              },
              "&.MuiPickersDay-today": {
                color: theme.primary.textBase,
                ":not(.Mui-selected)": {
                  borderColor: theme.primary.borderBase,
                },
              },
            },
            "& .Mui-selected": {
              color: theme.primary.textBase,
              backgroundColor: theme.primary.backgroundBase,
              "&:hover": {
                backgroundColor: theme.primary.backgroundHover,
                color: theme.primary.textBase,
              },
            },
          },
        },
      },
    },
  });
