// @ts-strict-ignore
const validHexRegex = new RegExp(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i);

export const hexToRgb = (hex: string): string => hexToRgba(hex, 1, true);

export const hexToRgba = (hex: string, opacity?: number, omitAlpha = false): string => {
  const result = validHexRegex.exec(hex);

  if (!result) return null;

  const hexWithoutHash = hex.replace(/^#/, "");

  // Parse r, g, b values
  const r = parseInt(hexWithoutHash.slice(0, 2), 16);
  const g = parseInt(hexWithoutHash.slice(2, 4), 16);
  const b = parseInt(hexWithoutHash.slice(4, 6), 16);

  if (omitAlpha) return `rgb(${r}, ${g}, ${b})`;

  let alpha = 1.0; // Default to fully opaque
  if (hexWithoutHash.length === 8) {
    alpha = parseInt(hexWithoutHash.slice(6, 8), 16) / 255;
  }

  // If an opacity argument is provided, it overrides the alpha value from the HEX code
  if (typeof opacity === "number") {
    alpha = Math.max(0, Math.min(1, opacity));
  }

  // Return the RGBA representation
  return `rgba(${r}, ${g}, ${b}, ${alpha.toFixed(1)})`;
};
