// @ts-strict-ignore
import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";

import { dimensions } from "@styles";

const base = css`
  font-weight: ${typography.weightBase};
  line-height: 1.125;
`;

export const titleBase = (theme: Theme) => css`
  ${base};
  font-size: ${typography.size2xlarge};

  strong {
    color: ${theme.success.textBase};
    font-weight: inherit;
  }
`;

export const paragraphBase = css`
  ${base};
  font-weight: ${typography.weightBase};
  font-size: ${typography.sizeNormal};
`;

// Modifiers
export const margin = css`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
export const indented = css`
  margin-left: 1rem;
`;
export const fullwidth = css`
  width: 100%;
`;

// Font colors
export const isSubdued = (theme: Theme) => css`
  color: ${theme.text.subdued};
`;

const getSemiBoldWeight = (isSecondaryWeight, theme: Theme) => {
  if (isSecondaryWeight) {
    return css`
      color: ${theme.text.body};
      font-weight: ${typography.weightBase};
    `;
  }

  return css`
    color: ${theme.text.emphasis};
    font-weight: ${typography.weightSemiBold};
  `;
};

// Sizes
export const titleSizes = {
  h1: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.h1.fontSize};
    font-family: ${theme.aviaryTypography.h1.fontFamily};
    line-height: ${theme.aviaryTypography.h1.lineHeight};
    font-weight: ${isSecondaryWeight
      ? typography.weightBase
      : theme.aviaryTypography.h1.fontWeight};
    color: ${isSecondaryWeight ? theme.text.body : theme.text.emphasis};

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.mobileH1.fontSize};
    }
  `,
  h2: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.h2.fontSize};
    font-family: ${theme.aviaryTypography.h2.fontFamily};
    line-height: ${theme.aviaryTypography.h2.lineHeight};
    font-weight: ${isSecondaryWeight
      ? typography.weightBase
      : theme.aviaryTypography.h2.fontWeight};
    color: ${isSecondaryWeight ? theme.text.body : theme.text.emphasis};

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.mobileH2.fontSize};
      line-height: 2rem;
    }
  `,
  h3: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.h3.fontSize};
    font-family: ${theme.aviaryTypography.h3.fontFamily};
    line-height: ${theme.aviaryTypography.h3.lineHeight};
    font-weight: ${isSecondaryWeight
      ? typography.weightBase
      : theme.aviaryTypography.h3.fontWeight};
    color: ${isSecondaryWeight ? theme.text.body : theme.text.emphasis};

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.mobileH3.fontSize};
    }
  `,
  h4: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.h4.fontSize};
    font-family: ${theme.aviaryTypography.h4.fontFamily};
    line-height: ${theme.aviaryTypography.h4.lineHeight};
    font-weight: ${isSecondaryWeight
      ? typography.weightBase
      : theme.aviaryTypography.h4.fontWeight};
    color: ${isSecondaryWeight ? theme.text.body : theme.text.emphasis};
  `,
  h5: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.h5.fontSize};
    font-family: ${theme.aviaryTypography.h5.fontFamily};
    line-height: ${theme.aviaryTypography.h5.lineHeight};
    font-weight: ${isSecondaryWeight
      ? typography.weightBase
      : theme.aviaryTypography.h5.fontWeight};
    color: ${isSecondaryWeight ? theme.text.body : theme.text.emphasis};
  `,
};

export const paragraphSizes = {
  body: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.body.fontSize};
    font-family: ${theme.aviaryTypography.body.fontFamily};
    line-height: ${theme.aviaryTypography.body.lineHeight};
    color: ${isSecondaryWeight ? theme.text.emphasis : theme.text.body};
    font-weight: ${isSecondaryWeight
      ? typography.weightSemiBold
      : theme.aviaryTypography.body.fontWeight};
  `,
  footnote: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.footnote.fontSize};
    font-family: ${theme.aviaryTypography.footnote.fontFamily};
    line-height: ${theme.aviaryTypography.footnote.lineHeight};
    ${getSemiBoldWeight(!isSecondaryWeight, theme)}
  `,
  caption: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.caption.fontSize};
    font-family: ${theme.aviaryTypography.caption.fontFamily};
    line-height: ${theme.aviaryTypography.caption.lineHeight};
    ${getSemiBoldWeight(!isSecondaryWeight, theme)}
  `,
  subcaption: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.subcaption.fontSize};
    font-family: ${theme.aviaryTypography.subcaption.fontFamily};
    line-height: ${theme.aviaryTypography.subcaption.lineHeight};
    ${getSemiBoldWeight(!isSecondaryWeight, theme)}
  `,
};

export const sizeOverride = {
  h1: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.h1.fontSize};
    font-family: ${theme.aviaryTypography.h1.fontFamily};
    line-height: ${theme.aviaryTypography.h1.lineHeight};
    font-weight: ${theme.aviaryTypography.h1.fontWeight};

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.mobileH1.fontSize};
    }
  `,
  h2: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.h2.fontSize};
    font-family: ${theme.aviaryTypography.h2.fontFamily};
    line-height: ${theme.aviaryTypography.h2.lineHeight};
    font-weight: ${theme.aviaryTypography.h2.fontWeight};

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.mobileH2.fontSize};
      line-height: 2rem;
    }
  `,
  h3: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.h3.fontSize};
    font-family: ${theme.aviaryTypography.h3.fontFamily};
    line-height: ${theme.aviaryTypography.h3.lineHeight};
    font-weight: ${theme.aviaryTypography.h3.fontWeight};

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.mobileH3.fontSize};
    }
  `,
  h4: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.h4.fontSize};
    font-family: ${theme.aviaryTypography.h4.fontFamily};
    line-height: ${theme.aviaryTypography.h4.lineHeight};
    ${getSemiBoldWeight(isSecondaryWeight, theme)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.h4.fontSize};
    }
  `,
  h5: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.h5.fontSize};
    font-family: ${theme.aviaryTypography.h5.fontFamily};
    line-height: ${theme.aviaryTypography.h5.lineHeight};
    ${getSemiBoldWeight(isSecondaryWeight, theme)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.h5.fontSize};
    }
  `,
  body: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.body.fontSize};
    font-family: ${theme.aviaryTypography.body.fontFamily};
    line-height: ${theme.aviaryTypography.body.lineHeight};
    ${getSemiBoldWeight(!isSecondaryWeight, theme)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.body.fontSize};
    }
  `,
  footnote: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.footnote.fontSize};
    font-family: ${theme.aviaryTypography.footnote.fontFamily};
    line-height: ${theme.aviaryTypography.footnote.lineHeight};
    ${getSemiBoldWeight(!isSecondaryWeight, theme)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.footnote.fontSize};
    }
  `,
  caption: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.caption.fontSize};
    font-family: ${theme.aviaryTypography.caption.fontFamily};
    line-height: ${theme.aviaryTypography.caption.lineHeight};
    ${getSemiBoldWeight(!isSecondaryWeight, theme)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.caption.fontSize};
    }
  `,
  subcaption: (isSecondaryWeight, theme: Theme) => css`
    font-size: ${theme.aviaryTypography.subcaption.fontSize};
    font-family: ${theme.aviaryTypography.subcaption.fontFamily};
    line-height: ${theme.aviaryTypography.subcaption.lineHeight};
    ${getSemiBoldWeight(!isSecondaryWeight, theme)}

    @media (max-width: ${dimensions.phoneLargeMax}) {
      font-size: ${theme.aviaryTypography.subcaption.fontSize};
    }
  `,
};
