/*
 * NEW DS3 WCAG 2AA SPEC COLORS
 */

export const black = "#2E3A47";

// Grays
export const gray100 = "#2E3A47";
export const gray90 = "#36485C";
export const gray80 = "#415B73";
export const gray70 = "#58728C";
export const gray30 = "#9BB1C7";
export const gray20 = "#C5D7E5";
export const gray10 = "#F0F5FC";
export const gray05 = "#F5F8FC";

// Blues
export const blue100 = "#183C5E";
export const blue90 = "#1E4D78";
export const blue80 = "#215F8F";
export const blue70 = "#3175A8";
export const blue30 = "#BED4E8";
export const blue20 = "#A8D0E5";
export const blue10 = "#EBF1F7";
export const blue05 = "#F6F9FC";

/*
 * Shades
 */

export const white = "#ffffff";

/*
 * Social media icon colors
 */

export const facebookBlue = "#3C5A99";
export const twitterBlue = "#1DA1F2";
export const linkedInBlue = "#2867B2";

/*
 * The rest of the page is not part of the current Product Colors style guide.
 * Please clear with design before using for new products
 */
export const legacy = {
  babyBlue: "#d1e2f3",
  greenery: "#88b04b",
};
