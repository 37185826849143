// @ts-strict-ignore
import { faExclamationCircle, faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { CloseButton } from "@aviary/components/Button/CloseButton";
import { l } from "@aviary/locales/i18n";
import { FontAwesomeIcon } from "@shared/react-fontawesome";

import type { ToastType } from "./toastTypes";

import * as styles from "./Toast.styles";

interface Props {
  /**
   * Sets the color of the alert
   *
   * @default normal
   */
  type: ToastType;
  /**
   * Determines if the Toast wil auto-dismiss after the timeout period
   *
   * @default true
   */
  autoDismiss: boolean;
  /**
   * The time the toast stays on screen if autoDismiss is true
   *
   * @default 5000
   */
  autoDismissTimeout?: number;
  /**
   * Determines if the close button is visible, used in the situation if you require the users attention
   *
   * @default true
   */
  isCloseable?: boolean;
  /**
   * Callback for the close button
   */
  onDismiss?: () => void;
  /**
   * Callback mouseEnter, used to pause the autoDismissal timer
   */
  onMouseEnter?: () => void;
  /**
   * Callback mouseEnter, used to restart the autoDismissal timer
   */
  onMouseLeave?: () => void;
  /**
   * Remaining time left in the timer
   */
  timeRemaining?: number;
  /**
   * Toast content
   *
   * @default undefined
   */
  children?: ReactNode;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/feedback/Toast
 */
const Toast = (props: Props) => {
  const {
    type,
    autoDismiss,
    autoDismissTimeout,
    timeRemaining,
    children,
    isCloseable = true,
    onDismiss,
    ...rest
  } = props;
  const { t } = useTranslation();

  const styleBuilder = [styles.content, autoDismiss && styles.content, styles.types[type]];
  const timerBuilder = [styles.timer, styles.timerTypes[type]];
  const progressBuilder = [
    styles.timeDown(autoDismissTimeout, timeRemaining),
    styles.progressTypes[type],
  ];

  const renderIcon = () => {
    if (type === "normal") return null;

    const renderIconType = () => {
      if (type === "success") return faCheckCircle;
      if (type === "error") return faExclamationCircle;
      if (type === "warning") return faExclamationCircle;
    };

    const renderTitle = () => {
      if (type === "success") return t(l.aviary.toast.success);
      if (type === "error") return t(l.aviary.toast.error);
    };

    return <FontAwesomeIcon icon={renderIconType()} css={styles.icon} title={renderTitle()} />;
  };

  return (
    <div css={styles.base} {...rest} aria-live="polite">
      <div css={timerBuilder}>
        <div css={progressBuilder}>&nbsp;</div>
      </div>
      <div aria-labelledby={type} role="status" css={styleBuilder}>
        <div css={styles.contentWrapper}>
          {renderIcon()}
          {children}
        </div>
        {isCloseable && (
          <CloseButton
            isLight
            onClick={onDismiss}
            absolutePosition="topRight"
            aria-label={t(l.aviary.toast.CloseNotification)}
          />
        )}
      </div>
    </div>
  );
};

export type { Props as ToastProps };
export { Toast };
